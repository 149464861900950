import { TWS_API_URL } from '../../../environment/apis.config'
import { ALERTAPIParser } from '../../../utils/ALERTUtils'
import { setDisabledMenuItem } from '../../../menus/SideNavigation/SideNavigationSlice'
import store from '../../../store'

export const getActiveEvents = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${TWS_API_URL}/alerts`, {
        method: 'GET',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ` + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        reject(response.status)
      }
      const data = await response.json()
      if (data?.features.length > 0) {
        resolve(ALERTAPIParser(data))
      } else{
        resolve([[], 0])
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getActiveEventsAuto = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(`${TWS_API_URL}/auto/alerts`, {
        method: 'GET',
        headers: {
          // Authorization: `Bearer ` + sessionStorage.getItem('token'),
          Authorization: `Bearer ` + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        reject(response.status)
      }
      const data = await response.json()
      if (data?.features.length > 0) {
        resolve(ALERTAPIParser(data))
      } else{
        resolve([[], 0])
      }
    } catch (error) {
      reject(error)
    }
  })
}

export const getActiveEventsNotifiaction = async () => {
  try {
    const response = await fetch(`${TWS_API_URL}/alerts`, {
      method: 'GET',
      headers: {
        // Authorization: `Bearer ` + sessionStorage.getItem('token'),
        Authorization: `Bearer ` + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      if (response.status == 400) {
        store.dispatch(setDisabledMenuItem({ id: 'alerts-menu', disabled: true })) 
        store.dispatch(setDisabledMenuItem({ id: 'decision-routcase', disabled: true }))
      }
      return null
    }

    const data = await response.json()
    store.dispatch(setDisabledMenuItem({ id: 'alerts-menu', disabled: false })) 
    store.dispatch(setDisabledMenuItem({ id: 'decision-routcase', disabled: false }))
    if (data?.features.length > 0) {
      return ALERTAPIParser(data)
    } else {
      return null
    }
  } catch (error) {
    return error.message
  }
}

export default getActiveEvents
