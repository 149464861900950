import { Link, useLocation } from 'react-router-dom';

const MainNavMenu = () => {
  const location = useLocation();

  const breadcrumbMap: { [key: string]: string } = {
    '/map': 'Map',
    '/customthresholds': 'Custom Thresholds',
    '/userprofile': 'Your Profile',
    '/sensordetails': 'Weather Sensor Details',
    '/faq': 'Frequently Asked Questions'
  };

  const currentBreadcrumbText = breadcrumbMap[location.pathname] || 'Unknown Page';

  const breadcrumbItems = [
    { text: 'Map', link: '/map' },
    { text: currentBreadcrumbText, link: location.pathname },
  ];

  return (
    <div className='mt-2 mx-12 flex rounded-lg items-center justify-between'>
      <Link to="/map">
        <button type="button" className="text-gray-900 bg-white hover:bg-gray-100 font-light focus:ring-4 focus:outline-none focus:ring-gray-100 rounded-lg text-lg px-2 py-2 text-center inline-flex items-center me-2 mb-2">
        <svg className="w-8 h-8 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14M5 12l4-4m-4 4 4 4"/>
          </svg> Back
        </button>
      </Link>
    </div>
  );
};

export default MainNavMenu;
