/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { IMenuItem, sideMenuItems } from './SideNavItems' // Make sure to import your IMenuItem type
import { MenuItemCallback, registerMenuItemCallback } from './SideMenuCallbacks'
import { RootState } from '../../store'
import { MenuItem } from './SideNavigation'
import { act } from 'react-test-renderer'

/**
 * PLEASE READ
 * To add a menu item programatically, use the appendMenuItem action or the
 * prependMenuItem action.
 *
 * const item = {
 *   id: "an-id-that-will-be-used-for-this-action", // Required.
 *   label: "My Menu Item", // optional. ( for top-most menu item in heirarchy )
 *   link: 'https://www.google.com', //optional.
 *   icon: bigIcon, // optional.
 *   callback: verySpecialCallback({name: loc_name, lat: loc_lat, lng: loc_lng}), // optional. Use any extraData you need.
 *   multiSelect: false, // optional.
 *   subMenu: [], // required.
 * }
 * dispatch(appendMenuItem(item, parentMenuId))
 */

interface sideNavState {
  expanded: boolean
  menuItems: Array<IMenuItem>
  selectedMenuItem: IMenuItem | null
  previousMapSelection: IMenuItem | null
  selectedItems: string[]
  selectedItemIds: string[]
  stateChanged: string | null
}

const deepClone = (obj: any): any => {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }
  
  if (Array.isArray(obj)) {
    const copy: any[] = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepClone(obj[i]);
    }
    return copy;
  }
  
  const copy: { [key: string]: any } = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepClone(obj[key]);
    }
  }
  return copy;
};

const initialState: sideNavState = {
  expanded: true,
  menuItems: sideMenuItems, // Initialize with existing menu items
  selectedMenuItem: null,
  previousMapSelection: null,
  selectedItems: [],
  selectedItemIds: [],
  stateChanged: '',
}
function deepRemoveById(menu: IMenuItem[], idToRemove: string): void {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].id === idToRemove) {
      menu.splice(i, 1)
      return
    }

    if (menu[i].subMenu && menu[i].subMenu.length > 0) {
      deepRemoveById(menu[i].subMenu, idToRemove)
    }
  }
}

function deepAppend(
  item: IMenuItem,
  parentId: string,
  menu: IMenuItem[]
): void {
  for (const menuItem of menu) {
    if (menuItem.id === parentId) {
      if (!menuItem.subMenu) {
        menuItem.subMenu = []
      }
      menuItem.subMenu.push(item) // Append item to the subMenu
      return
    }

    if (menuItem.subMenu && menuItem.subMenu.length > 0) {
      deepAppend(item, parentId, menuItem.subMenu)
    }
  }
}

function deepPrepend(
  item: IMenuItem,
  parentId: string,
  menu: IMenuItem[]
): void {
  for (const menuItem of menu) {
    if (menuItem.id === parentId) {
      if (!menuItem.subMenu) {
        menuItem.subMenu = []
      }
      menuItem.subMenu.unshift(item) // Prepend item to the subMenu
      return
    }

    if (menuItem.subMenu && menuItem.subMenu.length > 0) {
      deepPrepend(item, parentId, menuItem.subMenu)
    }
  }
}

const insertBeforeAddLocation = (menuItems, newItem, parentId) => {
  for (let i = 0; i < menuItems.length; i++) {
    if (menuItems[i].id === parentId) {
      const addLocationIndex = menuItems[i].subMenu.findIndex(
        (item) => item.id === 'add-location'
      )
      if (addLocationIndex !== -1) {
        menuItems[i].subMenu.splice(addLocationIndex, 0, newItem)
        break
      }
    }

    if (menuItems[i].subMenu && menuItems[i].subMenu.length > 0) {
      insertBeforeAddLocation(menuItems[i].subMenu, newItem, parentId)
    }
  }
}

function deepSetActiveById(
  menu: IMenuItem[],
  idToActivate: string,
  activeStatus: boolean
): void {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].id === idToActivate) {
      menu[i] = {
        ...menu[i],
        active: activeStatus,
      }
      return
    }

    if (menu[i].subMenu && menu[i].subMenu.length > 0) {
      deepSetActiveById(menu[i].subMenu, idToActivate, activeStatus)
    }
  }
}

function getMenuItemById(menu: Array<IMenuItem>,id: string): IMenuItem | null {
  for (const item of menu) {
    if (item.id === id) {
      return deepClone(item); // Return a deep copy of the item
    }
    if (item.subMenu && item.subMenu.length > 0) {
      const found = getMenuItemById(item.subMenu, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

function deepSetDisabledById(
  menu: IMenuItem[],
  id: string,
  disabledStatus: boolean
): void {
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].id === id) {
      menu[i] = {
        ...menu[i],
        disabled: disabledStatus,
      }
      return
    }
    if (menu[i].subMenu && menu[i].subMenu.length > 0) {
      deepSetDisabledById(menu[i].subMenu, id, disabledStatus)
    }
  }
}

export const SideNavSlice = createSlice({
  name: 'sideNav',
  initialState,
  extraReducers: (builder) => {
    builder.addCase('RESET_STORE', () => initialState)
  },
  reducers: {
    initSideMenu: (state) => {
      return initialState
    },
    expand: (state) => {
      state.expanded = true
    },
    collapse: (state) => {
      state.expanded = false
    },

    selectMenuItemById: (state, action) => {
      const item = getMenuItemById(state.menuItems, action.payload)
      state.selectedMenuItem = item
    },

    selectMenuItem: (state, action) => {
      state.selectedMenuItem = action.payload
    },

    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload
    },

    setSelectedItemIds: (state, action) => {
      state.selectedItemIds = action.payload
    },

    selectedItems: (state, action) => {
      // const index = state.selectedItems.indexOf(action.payload)
      // if (index == -1) {
      //   state.selectedItems = [...state.selectedItems, action.payload]
      // } else {
      //   const len = state.selectedItems.length
      //   const items = [...state.selectedItems]
      //   state.selectedItems = [
      //     ...items.slice(0, index),
      //     ...items.slice(index + 1, len),
      //   ]
      // }
      state.selectedItems = state.selectedItems.includes(action.payload)
        ? state.selectedItems.filter((item) => item !== action.payload)
        : [...state.selectedItems, action.payload]
    },
    // need to use item ids to id menu items rather than item labels -HH
    selectedItemIds: (state, action) => {
      const index = state.selectedItemIds.indexOf(action.payload)
      if (index == -1) {
        state.selectedItemIds = [...state.selectedItemIds, action.payload]
      } else {
        const len = state.selectedItemIds.length
        const items = [...state.selectedItemIds]
        state.selectedItemIds = [
          ...items.slice(0, index),
          ...items.slice(index + 1, len),
        ]
      }
    },

    setActiveMenuItem: (
      state,
      action: PayloadAction<{ id: string; active: boolean }>
    ) => {
      const { id, active } = action.payload
      deepSetActiveById(state.menuItems, id, active)
    },

    deselectMenuItem: (state, action: PayloadAction<IMenuItem | null>) => {
      // You can use the action.payload here to get information about the deselected item
      deepSetActiveById(state.menuItems, action?.payload?.id, false)
      state.selectedMenuItem = null
    },

    deselectMenuItemById: (state, action: PayloadAction<string>) => {
      const idToRemove = action.payload
      const selectedItems = [...state.selectedItems]
      // Function to recursively find the item by ID and return its label
      function findLabelById(menu: IMenuItem[], id: string): string | null {
        for (const item of menu) {
          if (item.id === id) {
            return item.label || null // Assuming label exists, adjust accordingly if it might not
          } else if (item.subMenu && item.subMenu.length > 0) {
            const foundLabel = findLabelById(item.subMenu, id)
            if (foundLabel) return foundLabel
          }
        }
        return null
      }

      state.selectedItemIds = state.selectedItemIds.filter(
        (id) => id !== idToRemove
      )

      if (selectedItems.includes(idToRemove)) {
        state.selectedItems = [...selectedItems.filter(
          (label) => label !== idToRemove
        )]
      } else {
        const labelToRemove = findLabelById(state.menuItems, idToRemove)

        if (labelToRemove !== null) {
          state.selectedItems = [...selectedItems.filter(
            (label) => label !== labelToRemove
          )]
        }
      }

      deepSetActiveById(state.menuItems, idToRemove, false)
      state.selectedMenuItem = null
    },

    addMenuItem: (
      state,
      action: PayloadAction<{ item: IMenuItem; parentIndex: number }>
    ) => {
      const { item, parentIndex } = action.payload

      // Assuming the root menu item is always at index 0 and its subMenu property contains the actual menu items
      const rootMenu = state.menuItems[0]
      if (rootMenu && rootMenu.subMenu) {
        const parentMenu = rootMenu.subMenu[parentIndex]

        if (parentMenu && parentMenu.subMenu) {
          parentMenu.subMenu.push(item)
        } else {
          // Handle error or fallback logic, e.g., adding the item at the top level
        }
      } else {
        // Handle error or fallback logic, e.g., initializing rootMenu and its subMenu
      }
    },

    removeMenuItem: (state, action: PayloadAction) => {
      const idStr = action.payload
      if (state.selectedItemIds.includes(idStr)) {
        const index = state.selectedItemIds.indexOf(idStr)
        state.selectedItemIds = state.selectedItemIds.splice(index, 1)
      }
      deepRemoveById(state.menuItems, idStr)
    },
    clearSelectedItems: (state) => {
      state.selectedItems = []
    },

    prependMenuItem: (
      state,
      action: PayloadAction<{ item: IMenuItem; parentId: string }>
    ) => {
      const { item, parentId } = action.payload

      if (Array.isArray(item)) {
        for (let i = item.length - 1; i >= 0; i--) {
          const { callback, extraData, ...itemToStore } = item[i]
          deepPrepend(item[i], parentId, state.menuItems)
          if (
            item[i].id &&
            item[i].callback &&
            item[i].callback !== undefined
          ) {
            registerMenuItemCallback(item[i].id, callback as MenuItemCallback)
          }
        }
      } else {
        const { callback, extraData, ...itemToStore } = action.payload //item
        // deepPrepend(item, parentId, state.menuItems)
        deepPrepend(action.payload.item, parentId, state.menuItems)
        if (
          action.payload.id &&
          action.payload.callback &&
          action.payload.callback !== undefined
        ) {
          registerMenuItemCallback(
            action.payload.id,
            callback as MenuItemCallback
          )
        }
        // if (item.id && item.callback && item.callback !== undefined) {
        //   registerMenuItemCallback(item.id, item.callback as MenuItemCallback)
        // }
      }
    },

    setStateChanged: (
      state,
      action: PayloadAction<{ itemId: string | null }>
    ) => {
      state.stateChanged = action.payload.itemId
    },

    appendLocationMenu: (
      state,
      action: PayloadAction<{ menuItem: IMenuItem; parentId: string }>
    ) => {
      const { item, parentId } = action.payload
      insertBeforeAddLocation(state.menuItems, item, 'location-menu')
    },

    appendMenuItem: (
      state,
      action: PayloadAction<{ item: IMenuItem; parentId: string }>
    ) => {
      const { item, parentId } = action.payload
      const { callback, extraData, ...itemToStore } = item
      if (Array.isArray(item)) {
        for (let i = 0; i < item.length; i++) {
          deepAppend(item[i], parentId, state.menuItems)
          if (
            item[i].id &&
            item[i].callback &&
            item[i].callback !== undefined
          ) {
            registerMenuItemCallback(
              item[i].id,
              item[i].callback as MenuItemCallback
            )
          }
        }
      } else {
        deepAppend(item, parentId, state.menuItems)
        if (item.id && item.callback && item.callback !== undefined) {
          registerMenuItemCallback(item.id, item.callback as MenuItemCallback)
        }
      }
    },

    setDisabledMenuItem: (
      state,
      action: PayloadAction<{ id: string; disabled: boolean }>
    ) => {
      const { id, disabled } = action.payload
      deepSetDisabledById(state.menuItems, id, disabled)
    },
    enableAllMenuItems: (state) => {
      const menuItems = state.menuItems
      for (let i = 0; i < menuItems.length; i++) {
        deepSetDisabledById(menuItems, menuItems[i].id, false)
      }
    },
    disableAllMenuItems: (state) => {
      const menuItems = state.menuItems
      for (let i = 0; i < menuItems.length; i++) {
        deepSetDisabledById(menuItems, menuItems[i].id, true)
      }

      state.menuItems = [...menuItems]
    },
  },
})

export const {
  expand,
  collapse,
  selectMenuItem,
  selectedItems,
  selectedItemIds,
  setSelectedItems,
  setSelectedItemIds,
  selectMenuItemById,
  setActiveMenuItem,
  deselectMenuItem,
  deselectMenuItemById,
  addMenuItem,
  removeMenuItem,
  prependMenuItem,
  appendMenuItem,
  appendLocationMenu,
  setStateChanged,
  clearSelectedItems,
  initSideMenu,
  setDisabledMenuItem,
  disableAllMenuItems,
  enableAllMenuItems,
} = SideNavSlice.actions

export default SideNavSlice.reducer
